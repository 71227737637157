.insights {
  &__header {
    padding: 20px 50px;

    display: flex;
    flex-direction: column;
    gap: 30px;

    color: white;
    background-color: var(--dark-blue);
  }

  &__title {
    font-size: 40px;
    font-weight: 600;
  }

  &__subtitle {
    font-size: 30px;
  }

  &__preview-group {
    display: flex;
    justify-content: space-between;
  }

  &__preview-item {
    width: 12%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    &:nth-child(2) {
      align-items: center;
      text-align: center;
    }

    &:last-child {
      width: 7%;
      align-items: flex-end;
      text-align: end;
    }
  }

  &__preview-title {
    font-size: 20px;
  }

  &__preview-value {
    width: 100%;
    padding-top: 10px;
    border-top: solid 1px white;

    font-size: 28px;
    font-weight: 500;
  }

  &__not-available {
    width: 100%;
    padding-top: 10px;
    border-top: solid 1px white;

    font-size: 20px;
    color: var(--text-secondary);
  }

  &__main {
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    background-color: white;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
  }

  &__row-item {
    width: 50%;
    min-width: 550px;
    flex-grow: 1;
  }
}

.audience-group {
  display: flex;
  flex-direction: column;
  gap: 30px;

  .group__title {
    width: 50%;
    font-size: 48px;
    font-weight: 700;
  }

  .group__description {
    color: var(--text-secondary);
  }

  &.align-end {
    .group__title {
      text-align: end;
      align-self: flex-end;
    }
  }
}

.audience-row {
  display: flex;
  justify-content: space-between;
}

.audience-column {
  display: flex;
  flex-direction: column;

  &__title, &__value {
    font-size: 34px;
    font-weight: 700;
  }

  &__description {
    font-size: 22px;
    font-weight: 500;
  }

  &.align-end {
    text-align: end;
  }
}

// Highcharts
.highcharts-background {
  fill: transparent;
}

@include screen-mid {
  .insights {
    &__main {
      padding: 20px;
    }

    &__row-item {
      min-width: 300px;
    }
  }

  .audience-group {
    .group__title {
      width: 100%;
      text-align: center !important;
      align-items: center !important;

      font-size: 28px;
    }
  }
}
