.dialog-info {
  cursor: default;
  padding: 20px 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-size: 24px;
    font-weight: 600;
    color: var(--red);

    &.dialog-confirm {
      color: var(--primary-blue);
    }
  }

  &__actions {
    align-self: flex-end;
    display: flex;
    gap: 10px;
  }

  &__btn {
    padding: 5px 20px;

    color: var(--red);
    border: solid 1px var(--red);

    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      color: var(--white);
      background-color: var(--red);
    }

    &.dialog-confirm {
      &:first-child {
        color: var(--gray);
        border: solid 1px var(--gray);

        &:hover {
          color: var(--white);
          background-color: var(--gray);
        }
      }

      &:last-child {
        color: var(--primary-blue);
        border: solid 1px var(--primary-blue);

        &:hover {
          color: var(--white);
          background-color: var(--primary-blue);
        }
      }
    }
  }
}
