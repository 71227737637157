.wrapper {
  margin: 0 auto;
  padding: 5vh 5vw;
  max-width: 1864px;

  @media screen and (max-width: 1220px) {
    padding-top: 120px;
  }
}

.close-dialog-btn {
  position: absolute;
  top: 5px;
  right: 5px;

  cursor: pointer;
  transition: all .1s ease-in;

  &:hover {
    color: var(--primary-blue);
  }
}

.warning-section {
  width: 50%;
  margin: 0 auto;
  padding: 10px 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  font-size: 18px;
  font-weight: 600;

  border-radius: 12px;
  border: solid 2px var(--warning);
  background-color: rgba(247, 198, 0, 0.1);

  &__icon {
    svg path {
      fill: var(--warning);
    }
  }

  @include screen-small {
    padding: 5px 10px;

    flex-direction: column;
    gap: 5px;

    font-size: 14px;
  }
}
