.top-bar-bg { // For very wide screens
  background-color: var(--white);
}

.top-bar {
  min-height: 90px;
  padding: 0 80px;

  user-select: none;
  background-color: var(--white);

  display: flex;
  align-items: center;
  justify-content: space-between;

  &__logo {
    cursor: pointer;
    transition: all .2s ease-in;

    &:hover {
      filter: drop-shadow(0 0 2px var(--gray));
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    gap: 24px;

    color: var(--gray);
    font-size: 16px;
    font-weight: 400;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }

  &__link {
    margin: 0 auto;

    position: relative;
    overflow: hidden;
    cursor: pointer;
    text-decoration: unset;

    transition: all .1s ease-in;

    svg path {
      transition: all .1s ease-in;
    }

    &:hover {
      color: var(--primary-blue);

      svg path {
        stroke: var(--primary-blue);
      }
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transform-origin: 50% 50%;
      transform: scale(0);
      width: 100%;
      height: 1px;
      background-color: var(--primary-blue);
      transition: all .2s ease-in;
    }

    &:hover:after {
      transform: scale(1);
    }
  }

  & .button {
    height: 50px;
    padding: 10px 20px;
  }

  &__group {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__logout {
    height: 50px;
    padding: 0 20px;

    display: flex;
    align-items: center;
    gap: 6px;

    font-weight: 700;
    color: var(--black);
    border: solid 1px var(--black);

    cursor: pointer;
    transition: all .1s ease-in;

    .icon svg path {
      transition: all .1s ease-in;
    }

    &:hover {
      color: var(--white);
      background-color: var(--black);

      .icon svg path {
        stroke: var(--white);
      }
    }
  }

  &__language {
    position: relative;
    cursor: pointer;

    .arrow {
      transition: all .2s ease-in;

      &.arrow-up {
        transform: rotate3d(1, 0, 0, 180deg);
      }
    }
  }

  &__dropdown {
    position: absolute;
    top: 110%;
    left: 0;

    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  &__burger-btn {
    display: none;
  }
}

@media screen and (max-width: 1450px) {
  .top-bar {
    padding: 0 20px;
  }
}

@media screen and (max-width: 1220px) {
  .top-bar {
    position: fixed;
    width: 100%;
    z-index: 10001;

    &__menu {
      display: none;
    }

    &__burger-btn {
      display: block;
      width: 45px;
      height: 40px;
      padding: 12px 0;
      cursor: pointer;

      .bar {
        width: 100%;
        height: 5px;

        background-color: var(--primary-blue);
        transition: 0.2s ease-in;

        &.bar1 {
          transform: translateY(-4px);
        }

        &.bar3 {
          transform: translateY(4px);
        }
      }

      &.opened {
        .bar.bar1 {
          transform: translateY(4px) rotateZ(-45deg);
        }

        .bar.bar2 {
          opacity: 0;
        }

        .bar.bar3 {
          transform: translateY(-6px) rotateZ(45deg);
        }
      }
    }
  }
}
