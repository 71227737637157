:root {
  --primary-blue: #417FE6;
  --primary-blue-07: rgba(65, 127, 230, 0.7);
  --dark-blue: #0A1932;
  --light-blue: #8595B0;
  --light-blue2: #8fb8ff;
  --black: #0B0B0BFF;
  --white: #FFFFFFFF;
  --white2: #EBEEFF;
  --gray: #666;
  --light-gray: #CDD1DC;
  --dark-puprle: #1E202C;
  --red: #FB2047;
  --contrast-red: #E01717;
  --red-transparent: rgba(238, 43, 55, 0.50);
  --ivory: #F3F3F1FF;
  --placeholder-color: rgba(65, 127, 230, 0.4);
  --bg-color: #EBF3F7FF;
  --active-button: #376EC9FF;
  --disabled-button: #C3D2ECFF;
  --overlay-background-color: rgba(235, 243, 247, 0.3);
  --warning: #F7C600;

  --text-secondary: #838E94;
  --post-updated: #41E2BA;
  --post-unseen: #e0e2e5;
}
