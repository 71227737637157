.post-feedback-page {
  z-index: 1;
  position: relative;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 80px;

  &__title {
    font-size: 42px;
    text-align: center;

    padding: 0 10px;
    background-color: var(--bg-color);

    @include screen-mid {
      font-size: 36px;
    }

    @include screen-small {
      font-size: 28px;
    }
  }

  &__success-title {
    color: var(--primary-blue);
    font-size: 20px;
    text-align: center;
  }

  &__post-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  &__post-item {
    padding: 10px;
    width: 300px;
    height: 600px;
    max-height: 600px;

    display: flex;
    flex-direction: column;
    gap: 10px;

    box-shadow: 0 0 5px var(--light-gray);
    background-color: var(--white);
  }

  &__post-image, &__video {
    cursor: pointer;
    width: 100%;
    height: 50%;
    object-fit: cover;
  }

  &__info {
    padding: 5px 10px;

    display: flex;
    align-items: center;
    gap: 10px;

    background-color: var(--ivory);
  }

  &__column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__avatar {
    width: 50px;
    height: 50px;

    object-fit: cover;
    border-radius: 50%;
  }

  &__username {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    text-decoration: none;
    color: var(--primary-blue);
    font-size: 16px;
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }

  &__followers {
    font-size: 12px;
  }

  &__soc-icon {
    margin-left: auto;
  }

  &__price {
    padding: 5px 10px;

    display: flex;
    justify-content: space-between;

    font-weight: 600;
    color: var(--white);
    background-color: var(--dark-blue);
  }

  &__desc {
    font-size: 12px;
    overflow-y: auto;
  }

  &__actions {
    margin-top: auto;
    display: flex;
    justify-content: space-around;

    .feedback-button {
      color: #F49752;

      cursor: pointer;
      transition: all .1s ease-in;

      &:first-child {
        color: #B2DE9E;
      }

      &:last-child {
        color: #E28B8B;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  &__confirm-btn {
    height: 50px;
    width: 250px;
    font-size: 20px;
  }

  &__bg {
    width: 100%;
    height: 200px;

    z-index: -1;
    position: absolute;
    top: 18vh;

    background-color: var(--dark-blue);
  }
}
