.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 120px;
  }

  &__block {
    min-height: 400px;
    padding: 70px 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 20px;

    background-color: var(--dark-blue);

    @include screen-small {
      padding: 40px 50px;
    }
  }

  &__subtitle {
    font-size: 42px;
    text-align: center;
    color: var(--white);
  }

  &__text {
    font-size: 34px;
    text-align: center;
    color: var(--white);
  }

  .button {
    margin-top: auto;
    height: 50px;
    width: 200px;
    font-size: 18px;
  }
}
