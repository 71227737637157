.analytics {
  &__title-column {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 24px;

    @include screen-small {
      font-size: 18px;
    }
  }

  &__header {
    padding: 20px 100px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    color: var(--white);
    background-color: var(--dark-blue);
    cursor: default;
  }

  &__header-column {
    min-width: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &:first-child {
      align-items: flex-start;
    }

    &:last-child {
      align-items: flex-end;
    }

    .analytics-value {
      min-width: 50px;
      padding-top: 5px;

      font-size: 24px;
      font-weight: 600;

      text-align: center;
      border-top: solid 1px var(--white);
    }
  }

  &__content {
    padding: 40px 100px;
    background-color: var(--white);

    display: flex;
    flex-direction: column;
    gap: 100px;

    @include screen-small {
      gap: 40px;
    }
  }

  &__content-column {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  &__empty-canvas {
    width: 100%;
    height: 92%;

    position: absolute;
    left: 0;
    bottom: 0;

    display: grid;
    place-items: center;

    backdrop-filter: blur(7px);

    @include screen-small {
      height: 80%;
    }
  }
}

@include screen-mid {
  .analytics {
    &__header, &__content {
      padding: 20px 50px;
    }
  }
}

@include screen-small {
  .analytics {
    &__header, &__content {
      padding: 20px 30px;
    }

    &__header {
      justify-content: center;
    }

    &__header-column {
      &:first-child, &:last-child {
        align-items: center;
      }
    }
  }
}
