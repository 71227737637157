.community-insights {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__title {
    font-size: 48px;
    font-weight: 700;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  &__subtitle {
    font-size: 32px;
    font-weight: 700;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    .community-insights__column {
      width: calc(50% - 20px); // 20px because of gap: 40px in &__row
      min-width: 500px;
      flex-grow: 1;
    }
  }

  &__column {
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    background-color: var(--white);
  }
}
