.contact-brand {
  width: 500px;
  max-height: 95vh;
  overflow-y: hidden;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &__title {
    font-size: 26px;
  }

  &__posts-section {
    width: 100%;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  &__post-item {
    width: 80%;
    padding: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    background-color: var(--ivory);
  }

  &__post-img {
    height: 200px;
    width: 80%;
    object-fit: cover;
  }

  &__post-price {
    align-self: center;
    display: flex;
    gap: 5px;

    & > * {
      padding: 10px;

      display: flex;
      align-items: center;

      color: var(--white);
      background-color: var(--primary-blue);
    }
  }

  &__price-field {
    gap: 10px;

    .input {
      width: 70px;
      padding: 5px;
      box-shadow: none;
      text-align: center;
    }
  }

  &__total-section {
    width: 100%;

    & > span {
      padding: 0 20px;
      font-size: 14px;
      color: var(--text-secondary);
    }
  }

  &__total-row {
    padding: 5px 20px;
    font-size: 18px;

    display: flex;
    justify-content: space-between;

    color: var(--white);
    background-color: var(--dark-blue);
  }

  &__form {
    width: 100%;
    padding: 0 20px;
    align-self: flex-start;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__toggler {
    font-size: 16px;

    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__show-prices-block {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__btn {
    align-self: center;
    margin-top: 10px;
  }
}
