.dialog-login {
  &__main {
    align-items: flex-end;
    gap: 9px;

    @include screen-small {
      gap: 20px;
    }
  }

  &__form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  &__field {
    flex-grow: 1;
    min-width: 200px;
  }

  &__forgot-password {
    display: flex;
    align-items: center;
    gap: 6px;

    color: var(--dark-blue);
    font-size: 14px;
    font-weight: 400;

    cursor: pointer;
  }
}
