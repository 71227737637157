.create-campaign {
  &__step-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  &__column {
    width: 45%;
    min-width: 350px;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;

    &.small-column {
      flex-grow: 0;
      width: auto;
      gap: 10px;
    }

    &.fullwidth-column {
      width: 100%;
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: space-between;

    // START: To replace on common name
    &.align-center {
      align-items: center;
    }

    &.justify-left {
      justify-content: left;
      gap: 5px;
    }
    // END

    &.small-row {
      align-items: center;
      justify-content: left;
      gap: 20px;
    }
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    button:last-child {
      margin-left: auto;
    }
  }
}
