.footer-bg { // For very wide screens
  background-color: var(--dark-blue);
}

.footer {
  min-height: 150px;
  padding: 40px 80px;
  background-color: var(--dark-blue);

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  &__soc-icons {
    flex-grow: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

    @include screen-small {
      gap: 20px;
    }
  }

  &__soc-icon {
    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &__links {
    flex-grow: 1;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  &__link {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #626B7B;

    text-decoration: none;

    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      color: var(--white);
    }
  }
}
