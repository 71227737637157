.campaigns {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    color: var(--black);
    font-size: 48px;
    font-weight: 700;
  }

  &__search-section {
    padding: 35px 180px;

    display: flex;
    flex-direction: column;
    gap: 40px;

    background: var(--dark-blue);
  }

  &__search-row {
    display: flex;
    align-items: center;
    gap: 40px;
  }

  &__search-input {
    flex-grow: 1;

    height: 50px;
    padding-left: 40px;

    background-image: url("/assets/icons/campaigns/search.svg");
    background-repeat: no-repeat;
    background-position: 11px center;

    transition: all .2s ease-in;

    &:focus {
      padding-left: 11px;
      background-position: -40px center;
      text-indent: 0;
    }
  }

  &__interests-btn {
    height: 50px;
    padding: 0 20px;

    display: flex;
    align-items: center;
    gap: 10px;

    font-size: 16px;
    font-weight: 700;

    color: var(--white);
    border: solid 1px var(--white);

    cursor: pointer;
    transition: all .1s ease-in;

    .icon svg path {
      transition: all .1s ease-in;
    }

    &:hover, &.filled {
      color: var(--dark-blue);
      background-color: var(--white);

      .icon svg path {
        stroke: var(--dark-blue);
      }
    }

    &:active {
      background-color: var(--light-gray);
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    web-campaign-item {
      flex: 0 0 28%;
      min-width: 300px;
      max-width: 400px;
    }
  }

  &__empty-list {
    font-size: 20px;
    color: var(--white);
  }

  &__load-more {
    padding: 40px 0 20px;
    display: grid;
    place-items: center;
  }

  &__interest-list {
    max-height: 300px;
    overflow-y: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    color: var(--white);

    label {
      width: 300px;

      display: flex;
      align-items: center;
      gap: 10px;

      cursor: pointer;
      transition: all .1s ease-in;

      &:hover, &.selected {
        color: var(--primary-blue);
      }
    }
  }
}

.campaign-item {
  cursor: pointer;

  &:hover {
    .campaign-item__overlay {
      opacity: 1;
    }
  }

  &__img-wrapper {
    height: 220px;
    position: relative;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title {
    color: var(--dark-blue);
    font-size: 18px;
    font-weight: 500;
  }

  &__overlay {
    opacity: 0;
    transition: all .1s ease-in;

    height: 100%;
    width: 100%;
    padding: 0 20%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: var(--red-transparent);
    backdrop-filter: blur(2.5px);
  }

  &__on-review {
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: grid;
    place-items: center;

    font-size: 18px;

    color: var(--white);
    backdrop-filter: blur(5px);
    background-color: var(--primary-blue-07);
  }

  &__group {
    width: 100%;
    display: flex;
    justify-content: space-between;

    div {
      color: var(--white2);
      font-size: 16px;
      font-weight: 400;

      &:last-child {
        font-weight: 700;
      }
    }

    &:last-child {
      gap: 10px;
      align-items: baseline;
    }
  }

  &__underline {
    height: 1px;
    flex-grow: 1;
    background-color: var(--white2);
  }
}

// Mat Tabs
.campaigns {
  .mat-mdc-tab {
    &.mdc-tab {
      height: 150px !important;
      padding: 0;
      background-color: var(--dark-blue);

      @include screen-small {
        height: 100px !important;
      }

      & .mdc-tab__text-label {
        color: var(--dark-blue);
        font-size: max(2vw, 3vh);
        font-weight: 500;

        @include screen-large {
          font-size: 30px;
        }
      }

      & .mdc-tab__content {
        background-color: var(--bg-color);
        width: 100%;
        height: 60px;
        padding: 20px 60px;
        display: flex;

        @include screen-small {
          padding: 10px 20px;
          height: 40px;
        }
      }

      &:first-child {
        .mdc-tab__content {
          justify-content: flex-end;
        }
      }

      &:last-child {
        .mdc-tab__content {
          justify-content: flex-start;
        }
      }
    }

    &.mdc-tab--active {
      & .mdc-tab__text-label {
        color: var(--white) !important;
      }

      & .mdc-tab__content {
        background-color: transparent;
      }
    }

    & .mdc-tab-indicator__content--underline {
      display: none;
    }
  }

  .mat-mdc-tab-body-wrapper {
    z-index: 1000;
    margin-top: -60px;

    @include screen-small {
      margin-top: -40px;
    }
  }
}

@include screen-mid {
  .campaigns {
    &__search-section {
      padding: 20px 50px;
    }

    &__search-input {
      height: 40px;
    }
  }
}

@include screen-small {
  .campaigns {
    &__search-row {
      flex-direction: column;
      gap: 20px;
    }

    &__interest-list {
      gap: 40px;
      font-size: 18px;
    }
  }
}
