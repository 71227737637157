.input {
  width: 100%;
  padding: 8px 11px;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  background: var(--white);
  box-shadow: 0 0 0 1px var(--light-gray);

  border: none;
  outline: none;
}

// Disabled input
input:disabled {
  color: var(--text-secondary);
  background-color: transparent;
}

// Input with icon
.input-container {
  position: relative;

  .input-icon {
    padding-left: 34px;
  }

  &__icon {
    position: absolute;
    left: 11px;
    top: 50%;
    transform: translate(0, -50%);

    &.icon-right {
      left: auto;
      right: 11px;
    }
  }
}

// Disable input autofill styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: 5000000s ease-in-out 0s;
}

// Placeholder color
input {
  &::-webkit-input-placeholder {
    color: var(--placeholder-color);
  }

  &:-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &::-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &::placeholder {
    color: var(--placeholder-color);
  }
}

// Disable arrows for input type=number

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// Input type=checkbox
input[type=checkbox] {
  appearance: none;
  min-width: 12px;
  min-height: 12px;

  width: 12px;
  height: 12px;

  background-color: var(--white);
  border: solid 1px var(--white);
  outline: solid 1px var(--primary-blue);

  cursor: pointer;
  transition: all .1s ease-in;

  &:checked {
    //background-image: url("/assets/icons/common/checkbox.svg");
    //background-position: center;
    //background-repeat: no-repeat;
    background-color: var(--primary-blue);
  }
}
