.change-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;

  &__title {
    font-size: 40px;

    @include screen-small {
      font-size: 30px;
    }
  }

  &__form {
    width: 500px;

    display: flex;
    flex-direction: column;
    gap: 30px;

    @include screen-small {
      width: 300px;
    }
  }

  &__btn {
    height: 60px;
    width: 200px;
    font-size: 18px;
  }
}
