.influencers-step {
  --mdc-slider-handle-color: var(--primary-blue);
  --mdc-slider-focus-handle-color: var(--primary-blue);
  --mdc-slider-active-track-color: var(--primary-blue);
  --mdc-slider-inactive-track-color: var(--primary-blue);
  --mdc-slider-label-container-color: transparent;
  --mdc-slider-label-label-text-color: var(--dark-blue);

  .mdc-slider .mdc-slider__value-indicator {
    transform: scale(1);

    .mdc-slider__value-indicator-text {
      font-size: 18px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.small-row {
      justify-content: left;
      gap: 20px;
    }
  }

  &__btn {
    &.button {
      border: solid 2px transparent;
    }

    &.secondary-btn {
      color: var(--primary-blue);
      border: solid 2px var(--primary-blue);

      &:hover {
        background-color: var(--primary-blue);
      }
    }
  }
}

.interests-wrapper {
  width: 100%;
  max-height: 800px;
  overflow-y: auto;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  user-select: none;
}

.interests-item {
  width: calc(50% - 5px); // 5px because of gap: 10px for .interests-wrapper
  min-width: 250px;
  height: 120px;
  padding: 10px 20px;

  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--primary-blue);
  background-color: var(--white);

  cursor: pointer;
  transition: all .1s ease-in;

  &.selected-interest {
    color: var(--white);
    background-color: var(--primary-blue);

    svg path {
      stroke: var(--white);
    }

    &:hover {
      background-color: var(--primary-blue);
    }

    &:active {
      background-color: var(--active-button);
    }
  }

  &:hover {
    color: var(--white);
    background-color: var(--light-blue2);

    svg path {
      stroke: var(--white);
    }
  }

  &:active {
    background-color: var(--active-button);
  }

  &__title {
    font-weight: 500;
    line-height: 28px;
  }

  &__icon {
    align-self: flex-end;

    svg path {
      transition: all .1s ease-in;
    }
  }
}

// Mat Tabs
.influencers-step {
  .mat-mdc-tab {
    &.mdc-tab {
      width: 50%;

      & .mdc-tab__text-label {
        color: var(--dark-blue);
        font-size: 16px;
        font-weight: 500;
      }
    }

    &.mdc-tab--active {
      & .mdc-tab__text-label {
        color: var(--primary-blue) !important;
      }

      & .mdc-tab__content {
        background-color: transparent;
      }
    }

    & .mdc-tab-indicator__content--underline {
      display: none;
    }
  }
}

.private-influencers {
  &__title {
    font-size: 26px;
    text-align: center;
  }

  &__text {
    color: var(--text-secondary);
  }

  &__influencer-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
}
