.paypal-dialog {
  padding: 30px;

  display: flex;
  flex-direction: column;
  gap: 30px;

  &__row {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__title {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
  }

  &__funds-section {
    padding: 20px;
    background-color: var(--dark-blue);
  }

  &__tips-section {
    padding: 0 50px;
    font-size: 14px;
    color: var(--text-secondary);
  }

  &__text {
    font-size: 24px;
    color: var(--white);

    &:last-of-type {
      font-weight: 700;
      border-bottom: solid 1px var(--white);
      align-self: flex-start;
    }
  }

  &__funds-field {
    .form-field__label {
      color: var(--white);
    }
  }

  &__btn {
    align-self: center;
  }
}
