.submit {
  &__left-column {
    align-items: flex-start;
  }

  &__link {
    text-decoration: none;
    color: var(--primary-blue);

    &:hover {
      text-decoration: underline;
    }
  }
}
