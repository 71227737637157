.form-field {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  gap: 10px;

  &__label {
    color: var(--dark-puprle);
    font-size: 16px;
    font-weight: 600;

    &.extra-margin {
      margin-bottom: 30px;
    }
  }
}

.form-error {
  display: flex;
  align-items: center;
  gap: 6px;

  color: var(--red);
  font-size: 12px;
  font-weight: 400;
}

.error-color {
  color: var(--red);
}

.error-icon {
  svg path {
    fill: var(--red);
  }
}
