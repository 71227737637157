.campaign-step {
  &__upload-title {
    font-size: 18px;
    font-weight: 500;
  }

  &__upload-img-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.upload-img-box {
  user-select: none;
  position: relative;
  width: 100%;
  height: 340px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0 1px var(--light-gray);
  background-color: white;

  cursor: pointer;
  transition: all .1s ease-in;

  &:hover {
    background-color: #C3D2ECFF;
  }

  &.cropper-box {
    cursor: default;
    &:hover {
      background-color: white;
    }
  }

  input[type=file] {
    display: none;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text {
    color: var(--primary-blue);
    font-weight: 500;
  }

  &.hidden {
    display: none;
  }

  &.small-box {
    flex-grow: 1;
    width: 45%;
    min-width: 200px;
    height: 150px;
  }

  &__remove-icon {
    position: absolute;
    top: 1%;
    right: 1%;

    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      filter: brightness(0.8);
    }
  }
}
