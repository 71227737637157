.sidebar {
  width: 350px;
  height: 100vh;
  padding: 120px 20px 60px;
  overflow-y: auto;

  position: fixed;
  top: 0;
  right: -350px;
  z-index: 10000;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;

  font-size: 20px;

  background-color: var(--white);
  transition: all .2s ease-in;

  &.opened {
    right: 0;
    box-shadow: 0 0 5px var(--gray);
  }

  &__soc-icons {
    margin-top: auto;
    display: flex;
    gap: 20px;
  }

  &__soc-icon {
    cursor: pointer;

    svg path {
      fill: var(--primary-blue);
      transition: all .1s ease-in;
    }

    &:hover {
      svg path {
        filter: brightness(0.7);
      }
    }
  }

  .top-bar__links {
    gap: 20px;
  }

  .top-bar__link {
    text-align: right;
    margin: 0 0 0 auto;
  }

  .button {
    width: 250px;
    padding: 20px;
    font-size: 18px;
  }

  .top-bar__logout {
    width: 200px;
    padding: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sidebar-overlay {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;
  z-index: -9999;

  opacity: 0;
  backdrop-filter: blur(5px) brightness(0.6);

  transition: all .2s ease;

  &.opened {
    opacity: 1;
    z-index: 9999;
  }
}
