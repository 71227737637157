.brief-step {
  &__mood-images {
    width: 100%;
    max-height: 600px;
    overflow-y: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  &__soc-list {
    display: flex;
    align-items: center;
    gap: 10px;

    .soc-icon {
      cursor: pointer;

      &.selected-soc {
        svg path {
          fill: var(--primary-blue);
        }
      }
    }
  }

  &__todo-input {
    &.enabled {
      svg path {
        transition: all .1s ease-in;
        stroke: var(--primary-blue);
      }
    }
  }

  &__todo {
    .check-icon {
      svg path {
        stroke: var(--primary-blue);
      }
    }

    .cancel-icon {
      svg path {
        transition: all .1s ease-in;
      }

      &:hover {
        svg path {
          stroke: var(--red);
        }
      }
    }
  }

  &__relative-wrapper {
    position: relative;
  }

  &__add-btn {
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translate(0, -50%);

    background-color: white;
    opacity: 0;

    cursor: pointer;
    transition: all .1s ease-in;

    svg path {
      transition: all .1s ease-in;
    }

    &.enabled {
      opacity: 1;
    }

    &:hover {
      svg path {
        filter: brightness(0.8);
      }
    }
  }
}
