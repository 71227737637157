.textarea {
  width: 100%;
  height: 60px;
  padding: 8px 11px;

  font-size: 16px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  line-height: 24px;

  background: var(--white);
  box-shadow: 0 0 0 1px var(--light-gray);

  border: none;
  outline: none;
}

// Placeholder color
textarea {
  &::-webkit-input-placeholder {
    color: var(--placeholder-color);
  }

  &:-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &::-ms-input-placeholder {
    color: var(--placeholder-color);
  }

  &::placeholder {
    color: var(--placeholder-color);
  }
}
