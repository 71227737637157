.post-page {
  width: fit-content;
  height: 90vh;
  max-height: 700px;

  margin: 40px auto;
  padding: 20px 30px;

  &.post-dialog {
    width: 100%;
    margin: 0;
  }

  cursor: default;
  background-color: var(--white);

  &__row {
    height: 100%;
    overflow-y: auto;

    display: flex;
    flex-wrap: wrap;
    gap: 30px;

    &.history-view-wrapper {
      height: 90%;
      gap: 10px;
      flex-wrap: nowrap;
    }
  }

  &__section {
    width: 320px;
    height: 100%;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__header {
    display: flex;
    gap: 10px;
  }

  &__avatar {
    width: 60px;
    height: 60px;

    object-fit: cover;
    border-radius: 50%;
  }

  &__user-info {
    max-width: 60%;
  }

  &__username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 16px;
    font-weight: 700;
    color: var(--primary-blue);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &__followers {
    font-size: 12px;
  }

  &__soc-icon {
    min-width: 34px;
    min-height: 34px;
    margin-left: auto;
  }

  .cost-table {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &__text-small {
      font-size: 10px;
    }

    &__row {
      display: flex;
      gap: 5px;

      div {
        padding: 5px;
        line-height: 16px;
        background-color: var(--ivory);

        &:first-child {
          width: 70%;
          padding-left: 15px;
        }

        &:last-child {
          flex-grow: 1;
          padding-right: 15px;

          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }

    &__total {
      display: flex;
      gap: 5px;

      color: var(--white);
      background-color: var(--dark-blue);

      span {
        padding: 5px;
        font-weight: 700;

        &:first-child {
          width: 70%;
          padding-left: 15px;
        }

        &:last-child {
          flex-grow: 1;
          padding-right: 15px;

          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & span {
      text-transform: uppercase;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    font-weight: 600;
    color: #F49752;

    cursor: pointer;
    transition: all .1s ease-in;

    &:hover {
      filter: brightness(0.8);
    }

    &:first-child {
      color: #B2DE9E;
    }

    &:last-child {
      color: #E28B8B;
    }
  }

  &__bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__insights {
    width: 150px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    font-weight: 700;
    color: var(--primary-blue);
    border: solid 1px var(--primary-blue);

    cursor: pointer;
    transition: all .1s ease-in;

    &.blue-bg {
      background-color: var(--primary-blue);
    }

    &:hover {
      color: var(--white);
      background-color: var(--primary-blue);

      svg path {
        fill: var(--white);
      }
    }
  }

  &__new-tab-btn {
    width: 200px;

    &.black-bg {
      background-color: var(--black);
    }
  }

  &__status {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    font-weight: 600;

    & span {
      text-transform: uppercase;
    }

    .status-accepted {
      color: #B2DE9E;
    }

    .status-declined {
      color: #E28B8B;
    }
  }

  .disable-events {
    pointer-events: none;

    svg path {
      fill: var(--light-gray)
    }

    color: var(--light-gray);
  }

  // Content section
  &__img {
    height: 60%;
    width: 100%;
    cursor: pointer;
    object-fit: cover;
    align-self: center;

    &.video {
      object-fit: contain;
    }

    &.carousel-media {
      height: 90%;
      padding: 0 32px;
    }
  }

  &__text {
    font-size: 12px;
  }

  &__desc {
    overflow-y: auto;
  }

  &__submit-date {
    border-top: solid 1px #AAB1B5;
    padding-top: 10px;
    font-size: 10px;
    color: #838E94;

    & span {
      text-transform: uppercase;
    }
  }

  &__history-btn {
    display: block;
    margin: 0 auto 20px;
  }

  .carousel-swiper {
    --swiper-navigation-size: 24px;
  }
}

.history {
  &__arrow {
    width: 60px;
    background-color: rgba(65, 127, 230, 0.2);

    display: grid;
    place-items: center;

    cursor: pointer;
    user-select: none;
    transition: all .1s ease-in;

    &:hover {
      background-color: rgba(65, 127, 230, 0.3);
    }

    &:active {
      background-color: rgba(65, 127, 230, 0.5);
    }

    &.disabled-arrow {
      cursor: default;

      svg path {
        fill: rgba(65, 127, 230, 0.5);
      }

      &:hover, &:active {
        background-color: rgba(65, 127, 230, 0.2);
      }
    }
  }

  &__feedback-title {
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__feedback-list {
    max-height: 250px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__feedback-item {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    border-bottom: solid 1px var(--text-secondary);

    span {
      &:first-child {
        font-size: 14px;
      }

      &:last-child {
        font-size: 10px;
        color: var(--text-secondary);
      }
    }
  }
}
