.delete-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  &__title {
    font-size: 40px;
    text-align: center;
  }

  &__text {
    font-size: 26px;
    text-align: center;

    a {
      color: var(--primary-blue);
    }
  }

  &__image {
    height: 800px;
  }
}

@include screen-small {
  .delete-account {
    &__title {
      font-size: 32px;
    }

    &__text {
      font-size: 20px;
    }

    &__image {
      height: 500px;
    }
  }
}
