.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  &__title {
    font-size: 48px;
  }

  &__block {
    min-height: 400px;
    padding: 80px 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 40px;

    background-color: var(--dark-blue);
  }

  &__text {
    font-size: 40px;
    text-align: center;
    color: var(--white);
  }

  &__btn {
    width: 200px;
    height: 50px;
    font-size: 18px;
  }
}
