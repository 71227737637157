.campaign-details {
  min-height: 320px;
  padding: 40px 80px;

  z-index: 1;
  position: relative;

  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  &__background {
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    height: 70%;

    background-color: var(--dark-blue);
  }

  &__img-wrapper {
    height: 450px;
    width: 500px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    color: var(--white);
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 30px;
  }

  &__title {
    max-width: 700px;
    align-self: center;
    text-align: center;

    padding: 5px 20px;

    font-size: 32px;
    font-weight: 700;

    color: var(--dark-blue);
    background-color: var(--bg-color);
  }

  &__contact-btn {
    align-self: flex-end;
    height: 50px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    color: var(--white);
  }

  &__line {
    height: 1px;
    width: 50%;
    background-color: var(--white);
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__brand-owner-row {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }

  &__brand-owner-hint {
    display: flex;
    align-items: center;
    gap: 10px;

    & > span {
      cursor: default;
      font-size: 18px;
    }
  }
}

.details-group {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.align-right {
    text-align: right;
    align-items: flex-end;
  }

  &__subtitle {
    font-size: 24px;
  }

  &__hint {
    font-size: 16px;
    color: var(--text-secondary);
  }

  &__value {
    font-size: 32px;
    font-weight: 600;
  }
}

.details-analytics {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  gap: 50px;

  &__column {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__row { // ?? naming ??
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__value {
    font-size: 28px;
    font-weight: 600;
    line-height: 28px;
  }

  &__subtitle {
    line-height: 16px;
  }
}

.campaign-filters {
  min-height: 110px;
  padding: 20px 80px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;

  background-color: var(--white);

  &__row {
    flex-grow: 1;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 15px;
  }

  &__column {
    flex-grow: 1;
    min-width: 150px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;

    &.small-column {
      width: 150px;
    }
  }

  .button {
    flex-grow: 1;
  }

  &__soc-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  &__soc-icon, &__desc-icon {
    cursor: pointer;

    & svg path {
      transition: all .1s ease-in;
    }
  }

  &__soc-icon {
    &.active-soc-type, &:hover {
      svg path {
        fill: var(--primary-blue);
      }
    }

    &:active svg path {
      fill: var(--active-button);
    }
  }

  &__desc-icon {
    margin-bottom: 5px;

    &.active-desc, &:hover {
      svg path {
        stroke: var(--primary-blue);
      }
    }

    &:active svg path {
      stroke: var(--active-button);
    }
  }
}

@include screen-mid {
  .campaign-filters {
    &__soc-list, &__row {
      justify-content: center;
    }
  }
}

@include screen-small {
  .campaign-details {
    padding: 20px;

    &__img {
      max-width: 90%;
    }

    &__contact-btn {
      width: 80%;
      align-self: center;
    }
  }

  .campaign-filters {
    padding: 20px;
  }
}
