.dialog {
  min-height: 250px;
  padding: 20px 70px 30px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  border-radius: 0;
  background: var(--white);

  @include screen-mid {
    padding: 20px 30px;
    gap: 20px;
  }

  // Header
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
  }

  &__close {
    cursor: pointer;
  }

  // Main
  &__main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  // Footer
  &__footer {
    width: 40%;
    margin: 10px auto 0;

    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
