@mixin screen-large {
  @media screen and (min-width: 1024px) {
    @content
  }
}

@mixin screen-mid {
  @media screen and (max-width: 1023px) {
    @content
  }
}

@mixin screen-small {
  @media screen and (max-width: 767px) {
    @content
  }
}
