.profile {
  cursor: default;

  &__footer {
    padding: 0 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }
}

.account-details {
  display: flex;
  flex-direction: column;

  color: var(--white);
  background-color: var(--dark-blue);

  &__group {
    padding: 50px 150px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__title {
    font-size: 48px;
    font-weight: 700;
  }

  &__back-btn {
    padding: 14px 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    border: solid 1px var(--white);
    cursor: pointer;
    transition: all .1s ease-in;

    .icon svg path {
      transition: all .1s ease-in;
    }

    &:hover {
      color: var(--dark-blue);
      background-color: var(--white);

      .icon svg path {
        stroke: var(--dark-blue);
      }
    }
  }
}

@include screen-mid {
  .account-details {
    &__group {
      padding: 50px 50px 0;
    }
  }
  .profile {
    &__footer {
      padding: 0 50px;
    }
  }
}

@include screen-small {
  .account-details {
    &__group {
      padding: 25px 25px 0;
    }

    &__title {
      width: 100%;
      text-align: center;
    }

    &__back-btn {
      flex-grow: 1;
    }
  }
  .profile {
    &__footer {
      padding: 0 25px;
    }
  }
}
