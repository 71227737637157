.button {
  min-width: 160px;
  padding: 12px 14px;

  display: grid;
  place-items: center;

  color: var(--white);
  background-color: var(--primary-blue);

  font-size: 14px;
  font-weight: 700;

  cursor: pointer;
  transition: all .1s ease-in;

  border: none;
  outline: none;

  &:hover {
    background-color: var(--active-button);
  }

  &.disabled, &:disabled {
    cursor: default;
    background-color: var(--disabled-button);
  }

  &.secondary-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    font-weight: 700;
    color: var(--black);
    border: solid 1px var(--black);
    background-color: transparent;

    cursor: pointer;
    transition: all .1s ease-in;

    .icon svg path {
      transition: all .1s ease-in;
    }

    &:hover {
      color: var(--white);
      background-color: var(--black);

      .icon svg path {
        stroke: var(--white);
      }
    }
  }
}
