.activate-influencer {
  &__block {
    min-height: 500px;
    padding: 100px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 50px;

    box-shadow: 0 0 5px var(--gray);
    background-color: var(--dark-blue);
  }

  &__text {
    font-size: 40px;
    text-align: center;
    color: var(--white);

    @include screen-small {
      font-size: 30px;
    }
  }

  &__btn {
    height: 60px;
    width: 200px;
    font-size: 18px;
  }
}
