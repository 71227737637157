.profile-section {
  padding: 50px 150px;

  display: flex;
  flex-direction: column;
  gap: 50px;

  @include screen-mid {
    padding: 50px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
  }

  &__list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;

    .input {
      transition: all .1s ease-in;
    }

    &.no-edit {
      .input {
        color: #838E94;

        box-shadow: unset;
        pointer-events: none;
        background-color: transparent;

        transition: all .1s ease-in;

        &.white-text {
          color: var(--white);
        }
      }
    }
  }

  &__group {
    min-width: 200px;

    flex-grow: 1;
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__subtitle {
    font-weight: 700;
  }

  &__edit, &__change-password {
    align-self: flex-start;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--primary-blue);

    cursor: pointer;
  }

  &__edit-actions {
    display: flex;
    align-items: center;
    gap: 10px;

    div {
      cursor: pointer;
      transition: all .1s ease-in;

      &:hover {
        color: var(--primary-blue);
      }
    }
  }
}

.toggle-section {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    font-size: 32px;
    font-weight: 700;
  }

  &__text {
    font-weight: 700;
  }
}
