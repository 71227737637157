@import "mixins";

// Mat Dialog
.mat-dialog-backdrop {
  background: var(--overlay-background-color);
  backdrop-filter: blur(7.5px);
}

.mat-dialog-panel {
  box-shadow: 0 18px 34px 0 rgba(5, 16, 55, 0.15);
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 0 !important;
  box-shadow: none !important;
}

// Mat Spinner
.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: var(--dark-blue);

  &.white-spinner {
    --mdc-circular-progress-active-indicator-color: var(--white);
  }
}

.spinner {
  &__full-page, &__main-section {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;

    background-color: var(--overlay-background-color);
    backdrop-filter: blur(6px);
  }

  &__main-section {
    position: absolute;
  }

  &__local {
    margin: 20vh auto;
  }
}

// Mat Snackbar
.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: var(--primary-blue);
    --mat-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }

  &.failure-snackbar {
    --mdc-snackbar-container-color: var(--red);
    --mat-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

// Mat Stepper
.create-campaign__stepper {
  &.mat-stepper-horizontal {
    font-family: Poppins, sans-serif;
    background-color: transparent;
  }

  .mat-horizontal-stepper-header-container {
    padding: 0 80px;
    background-color: var(--dark-blue);

    @include screen-mid {
      justify-content: space-between;
    }

    @include screen-small {
      padding: 0 20px;
    }
  }

  .mat-horizontal-stepper-header {
    padding: 0 32px;
    pointer-events: none;

    @include screen-mid {
      padding: 0 24px;
    }

    @include screen-small {
      padding: 0 12px;
    }
  }

  .mat-step-text-label {
    color: var(--light-blue);
    font-size: 24px;
    font-weight: 700;

    @include screen-mid {
      font-size: 18px;
    }

    @include screen-small {
      font-size: 14px;
    }
  }

  .mat-step-label-selected {
    .mat-step-text-label {
      color: white;
    }
  }

  .mat-step-icon {
    display: none;
  }

  .mat-stepper-horizontal-line {
    margin: 0;
    border-top-color: var(--light-blue);

    @include screen-mid {
      display: none;
    }
  }

  // Content
  .mat-horizontal-content-container {
    padding: 24px;
  }
}

// Mat Tooltip
.mat-mdc-tooltip.custom-tooltip .mdc-tooltip__surface {
  max-width: 300px;
  font-size: 10px;
  background-color: var(--primary-blue);
}

// Mat Select
.mat-mdc-select {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  background: var(--white);
  box-shadow: 0 0 0 1px var(--light-gray);

  border: none;
  outline: none;
}

.mat-mdc-select-trigger {
  padding: 8px 11px;
}

.mat-mdc-option {
  &.mdc-list-item--selected .mdc-list-item__primary-text {
    color: var(--primary-blue) !important;
  }

  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    color: var(--red) !important;
  }
}

.cdk-overlay-pane {
  left: 0;
}

// Mat Slide Toggle
.mat-mdc-slide-toggle {
  // track
  --mdc-switch-selected-track-color: var(--primary-blue) !important;
  --mdc-switch-selected-hover-track-color: var(--primary-blue) !important;
  --mdc-switch-selected-focus-track-color: var(--primary-blue) !important;
  --mdc-switch-selected-pressed-track-color: var(--primary-blue) !important;

  // handle
  --mdc-switch-selected-handle-color: var(--active-button) !important;
  --mdc-switch-selected-hover-handle-color: var(--active-button) !important;
  --mdc-switch-selected-focus-handle-color: var(--active-button) !important;
  --mdc-switch-selected-pressed-handle-color: var(--active-button) !important;

  // layer (around handle)
  --mdc-switch-selected-hover-state-layer-color: var(--primary-blue) !important;
  --mdc-switch-selected-focus-state-layer-color: var(--primary-blue) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-blue) !important;
}

// Mat Datepicker
:root {
  --mat-datepicker-toggle-active-state-icon-color: var(--primary-blue);
  --mat-datepicker-toggle-icon-color: var(--dark-blue);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-blue);
}
