.post-feedback {
  height: 300px;
  padding: 20px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &__title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: var(--primary-blue);
  }

  &__textarea {
    flex-grow: 1;
    width: 100%;
    padding: 10px;

    border: solid 1px var(--light-gray);
    font-size: 14px;
    font-family: Poppins, sans-serif;

    resize: none;
    outline: none;
  }

  &__select {
    max-width: 600px;
    --mat-select-trigger-text-font: Poppins, sans-serif;
  }
}

.mat-mdc-option {
  --mat-option-label-text-font: Poppins, sans-serif;
}
