.review-step {
  &__title {
    &.big-title {
      font-size: 36px;
      font-weight: 700;
      margin: 40px 0;
    }
  }

  &__logo-img {
    width: 120px;
    height: 120px;
    object-fit: contain;
    user-select: none;
    box-shadow:  0 0 5px var(--light-gray);
  }

  &__hero-img {
    width: 100%;
    object-fit: contain;
    user-select: none;
    box-shadow:  0 0 5px var(--light-gray);
  }

  &__mood-images {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__mood-img {
    width: 300px;
    height: 140px;
    object-fit: cover;
    user-select: none;
    box-shadow:  0 0 5px var(--light-gray);
  }

  &__text {
    color: var(--text-secondary);
  }

  &__brand-comp-name {
    color: var(--contrast-red);
  }

  &__tags {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__tag {
    padding: 5px 10px;
    background-color: var(--disabled-button);
    border-radius: 100px;
  }

  &__do-dont-icon {
    align-self: flex-start;
  }
}
