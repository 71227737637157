.posts {
  max-width: 1430px; // 4 posts - 320px; 2 paddings - 30px; 3 gaps: 30px;
  margin: 0 auto;
  padding: 30px;

  &__empty-list-msg {
    margin: 50px auto;
    font-size: 18px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
}

.post-item {
  position: relative;

  width: 320px;
  height: 100%;
  padding: 10px 30px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  background-color: var(--white);

  cursor: pointer;
  transition: all .1s ease-in;

  &:hover {
    box-shadow: 0 0 5px var(--light-gray);
  }

  &__header {
    height: 32px;
    display: flex;
    gap: 7px;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__followers, &__engage {
    font-size: 12px;
  }

  &__price {
    margin-left: auto;

    font-size: 24px;
    font-weight: 600;
    color: var(--dark-blue)
  }

  &__main {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: var(--ivory);
  }

  &__user-info {
    padding: 0 8px;
    display: flex;
    gap: 5px;
  }

  &__avatar {
    width: 45px;
    height: 45px;

    object-fit: cover;
    border-radius: 50%;
  }

  &__username {
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-blue);
  }

  &__date {
    font-size: 12px;
    color: #B4B4B4;
  }

  &__main-img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  &__play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, - 50%);
  }

  &__desc {
    height: 24px;
    padding: 0 12px;
    font-size: 12px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.post-blur {
    filter: blur(4px);
    -webkit-filter: blur(4px);

    .post-item__main-img {
      filter: blur(10px);
      -webkit-filter: blur(10px);
    }
  }

  &.post-unseen {
    background-color: var(--post-unseen);
  }

  & .post-completed {
    width: 24px;
    height: 24px;

    position: absolute;
    right: 4px;

    border-radius: 100%;
    background-image: url('/assets/icons/posts/refresh.svg');
    background-size: contain;
  }

  & .post-seen {
    width: 10px;
    height: 10px;

    position: absolute;
    right: 10px;

    border-radius: 100%;
    background-color: var(--post-updated);
  }

  & .post-updated {
    width: 20px;
    height: 20px;

    position: absolute;
    right: 5.5px;

    background-image: url("/assets/icons/posts/danger.svg");
    background-size: contain;
  }

  &__status-icon {
    margin: 0 auto;
  }

  &__select {
    font-size: 14px;
    align-self: flex-end;
    display: flex;
    gap: 10px;
  }
}
